import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import TailwindLayout from '../../components/Layouts/TailwindLayout'
import ContactSupportIcon from '../../images/icons/contact.svg'
import { SupportPage } from '@truphone/support-components'
import { Helmet } from 'react-helmet'

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

function SupportProductIndex({
  data: { datoCmsSupportIndex, allDatoCmsSupportArticle, datoCmsSite },
  pageContext: { product, menu, footer, cookiePrompt, consumer, noIndex }
}) {
  const articles = useMemo(
    () =>
      Object.entries(
        groupBy('category')(
          allDatoCmsSupportArticle.edges
            .sort((a, b) => a.node.category.position - b.node.category.position)
            .map((edge) => ({
              title: edge.node.title,
              content: edge.node.subtitle,
              href: `${datoCmsSupportIndex.url}${edge.node.slug}/`,
              category: edge.node.category.title,
              position: edge.node.position
            }))
        )
      ).map((group) => ({
        title: group[0],
        list: group[1].sort((a, b) => a.position - b.position)
      })),
    [datoCmsSupportIndex.url, allDatoCmsSupportArticle]
  )

  return (
    <>
      <HelmetDatoCms seo={datoCmsSupportIndex.seoMetaTags} favicon={datoCmsSite.faviconMetaTags} />
      <Helmet>
        {noIndex && <meta name="robots" content="noindex,nofollow" />}
        <meta
          className="swiftype"
          name="searchKey"
          data-type="string"
          content={datoCmsSupportIndex.searchKey}
        />
        <meta
          className="swiftype"
          name="page_type"
          data-type="string"
          content="SupportProductIndex"
        />
      </Helmet>
      <TailwindLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt} consumer={consumer}>
        <SupportPage
          dark={!consumer}
          noResultsText={datoCmsSupportIndex.noResultsText}
          title={datoCmsSupportIndex.title}
          ctas={datoCmsSupportIndex.headerLink}
          searchEnabled={datoCmsSupportIndex.searchEnabled}
          searchKey={datoCmsSupportIndex.searchKey}
          placeholder={datoCmsSupportIndex.searchPlaceholder}
          categories={datoCmsSupportIndex.supportProducts.map((supportProduct) => ({
            icon: supportProduct.icon && supportProduct.icon.url,
            text: supportProduct.title,
            href: `${datoCmsSupportIndex.url}${supportProduct.slug}/`,
            selected: supportProduct.id === product
          }))}
          articlesList={articles}
          banner={{
            icon: ContactSupportIcon,
            title: datoCmsSupportIndex.contactSupportTitle,
            ctas: datoCmsSupportIndex.contactSupportCtas
          }}
        />
      </TailwindLayout>
    </>
  )
}

export default SupportProductIndex

export const query = graphql`
  query ($locale: String!, $supportIndex: String!, $product: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsSupportIndex(locale: { eq: $locale }, originalId: { eq: $supportIndex }) {
      seoMetaTags {
        tags
      }
      title
      headerLink {
        text
        title
        href
        openTab
      }
      url
      searchKey
      searchEnabled
      searchPlaceholder
      noResultsText
      supportProducts {
        id: originalId
        title
        slug
        icon {
          url
        }
      }
      contactSupportTitle
      contactSupportCtas {
        title
        text
        href
      }
    }
    allDatoCmsSupportArticle(
      filter: {
        locale: { eq: $locale }
        slug: { ne: null }
        supportProducts: { elemMatch: { originalId: { eq: $product } } }
      }
    ) {
      edges {
        node {
          title
          subtitle
          category {
            position
            title
          }
          slug
          position
        }
      }
    }
  }
`
